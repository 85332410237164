/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-09 08:48:25
 * @LastEditTime: 2022-04-09 09:00:55
 */
import wx from 'weixin-js-sdk'

// 获取本地cookie值
export const getCookie = (cName) => {
  if (document.cookie.length > 0) {
    let cStart = document.cookie.indexOf(cName + '=')
    if (cStart !== -1) {
      cStart = cStart + cName.length + 1
      let cEnd = document.cookie.indexOf(';', cStart)
      if (cEnd === -1) cEnd = document.cookie.length
      return decodeURI(document.cookie.substring(cStart, cEnd))
    }
  }
  return ''
}

// 微信分享API封装
export const initShareInfo = ({ title, desc, link, imgUrl }) => {
  const shareInfo = {
    title, // 分享标题
    desc: desc || 'ءبىلىم بىلگەن سايىن قىزىق', // 分享描述
    link: link || location.href,
    imgUrl,
    success: function (res) {
      console.log(res)
      // 分享成功后的回调函数
    },
    cancel: function () {
      console.log('cancel')
      // 用户取消分享后执行的回调函数
    }
  }

  wx.ready(() => {
    wx.onMenuShareAppMessage(shareInfo)
    wx.onMenuShareTimeline(shareInfo)
    wx.onMenuShareQQ(shareInfo)
    wx.onMenuShareQZone(shareInfo)
    wx.updateAppMessageShareData(shareInfo)
    wx.updateTimelineShareData(shareInfo)
  })
}

export const _isMobile = () => {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}
